(function ($) {
  'use strict';

  Drupal.behaviors.tabbedBlockV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }

      this.attached = true;

      var $templates = $('.js-tabbed-block--v1', context);

      $templates.each(function () {
        var $module = $(this);
        // Get the colors for the touts from the mustache template.
        // We're doing this to avoid issues with placing generic style tags inside of the mustache file
        //
        var activeStyle = {
          background: $module.data('active-background'),
          color: $module.data('active-text')
        };
        var defaultStyle = {
          'border-color': $module.data('border-color'),
          color: $module.data('text-color')
        };
        // Do our slide changing
        var $contentTriggers = $('.js-tab-trigger', $module);
        var $contentBlocks = $('.js-tab-content', $module);
        var $carousel = $('.js-basic-carousel', $module);
        $contentTriggers.on('click', function (event) {
          event.preventDefault();
          var index = $(this).index();
          var $activeBlock = $contentBlocks.eq(index);

          // Show this tab content
          $contentBlocks.addClass('hidden');
          $activeBlock.removeClass('hidden');
          if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('refresh');
          }
          $contentTriggers.not(this).removeClass('active');
          $(this).addClass('active');

          // If these styles are set in the template, apply our change
          // 1. Apply active styles to new tab
          // 2. Remove all styles from previous active tab and apply default styles
          if (activeStyle.background || activeStyle.color) {
            $(this).css(activeStyle);
            // If we have an active style set, make sure that it isnt lingering anywhere when we switch active tabs
            $contentTriggers.not(this).attr('style', '');
          }
          // Apply the default CSS styling if it exists
          if (defaultStyle['border-color'] || defaultStyle.color) {
            $contentTriggers.not(this).css(defaultStyle);
          }
        });

        // Start with first one clicked
        $contentTriggers.eq(0).trigger('click');
      });
    }
  };
})(jQuery);
